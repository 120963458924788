import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  search:string;
  onChangeSearch: BehaviorSubject<any>
  constructor() {
    this.onChangeSearch = new BehaviorSubject({});
  }

  setSeaarch(text){
    this.search = text;
    this.onChangeSearch.next(this.search);
  }
  setClear(){
    this.search = '';
    this.onChangeSearch.next(this.search);
  }

}
