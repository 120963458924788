import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ComponentsModule } from './components/components.module';
import { ImagesModule } from './pages/images/images.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AdminGuard } from './guards/admin.guard';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { CommonModule } from '@angular/common';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { BrindisComponent } from './brindis/brindis.component';
import { Brindis2Component } from './brindis2/brindis2.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    BrindisComponent,
    Brindis2Component
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxMaskModule.forRoot(options),
    RouterModule,
    AppRoutingModule,
    ComponentsModule,
  ],
  providers: [
    AdminGuard,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
  
})
export class AppModule { }
