import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminGuard } from './guards/admin.guard';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { LoginComponent } from './login/login.component';
import { BrindisComponent } from './brindis/brindis.component';
import { Brindis2Component } from './brindis2/brindis2.component';
import { Brindis2Service } from './brindis2/brindis2.service';


const routes: Routes = [
    {
      path: '',
      redirectTo: 'admin/posters',
      pathMatch: 'full',
    },
    {
      path: '',
      component: AdminLayoutComponent,
      canActivate: [AdminGuard],
      //data: { expectedRole: ['ROLE_ADMIN', 'ROLE_FACILITATOR'] },
      children: [
          {
            path: 'admin',
            loadChildren: './layout/admin-layout/admin-layout.module#AdminLayoutModule'
          }
      ]
    },
    { path: 'login', component: LoginComponent},
    { path: 'brindis', component: BrindisComponent},
    { path: 'brindis2', 
      component: Brindis2Component,
    },
    { 
      path: 'brindis/:hash', 
      component: Brindis2Component,
      resolve: {
        data: Brindis2Service
      }
    },
    
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: []
})
export class AppRoutingModule { }
