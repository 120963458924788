import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import config from '../config/config';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class Brindis2Service {

  brindis: any;
  onBrindisChange:BehaviorSubject<any>;
  routeParams:any;
  constructor(private httpClient:HttpClient) { 
    this.onBrindisChange = new BehaviorSubject({});
  }


  onSaveRegister(datos){
    return new Promise((resolve, reject)=>{
      this.httpClient.post(config.urlApi+'registers/create/'+this.routeParams.hash, datos)
      .subscribe(
        (response)=>{
          resolve(response);
        },
        (error)=>reject(error)
      );
    })
    
  }

  /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            Promise.all([
                this.getBrindis()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get product
     *
     * @returns {Promise<any>}
     */
    getBrindis(): Promise<any>
    {
      return new Promise((resolve, reject) => {          
          this.httpClient.get(config.urlApi+'brindis/public/' + this.routeParams.hash)
              .subscribe((response: any) => {
                  this.brindis = response;
                  this.onBrindisChange.next(this.brindis);
                  resolve(this.brindis);
          }, reject);
      });
  }

}
