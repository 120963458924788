import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Identity } from '../identity';
import { LoginService } from './login.service';
import { HttpClient } from '@angular/common/http';
import config from '../config/config';




@Injectable({
  providedIn: 'root'
})
export class IdentityService {
  
  identity;
  menu;
  private url = config.urlApi+'';
  onNameChanged: BehaviorSubject<any>;
  onMenuChanged: BehaviorSubject<any>;
  
  public _view: BehaviorSubject<string>;
  constructor(
    private _httpClient: HttpClient,
  ) {
    this.onNameChanged = new BehaviorSubject({});
    this.onMenuChanged = new BehaviorSubject({});
    this._view = <BehaviorSubject<string>> new BehaviorSubject(null);
  }

  
  get viewer$() {
    return this._view.asObservable();
  }

  public getIdentity():Promise<any> {
    return new Promise((resolve, reject)=>{
      if (!localStorage.getItem('identity')) {
        this.onNameChanged.next(false);
        resolve(false);
      }else {
        const identity = JSON.parse(localStorage.getItem('identity'));
        this.identity = identity;
        this.onNameChanged.next(identity);
        resolve(identity);
      }
    });
  }

  
  public getIdentityRaw() {
    const identity = JSON.parse(localStorage.getItem('identity')).user;
    return identity;
  }

  getRoles() {
    if (localStorage.getItem('identity')) {
      const identity = JSON.parse(localStorage.getItem('identity')).user;
      return identity.roles;
    } 
    return [];
  }
  getPreActive() {
    if (localStorage.getItem('identity')) {
      const identity = JSON.parse(localStorage.getItem('identity')).user;
      return identity.pre_active;
    } 
    return '';
  }
  getEnabled() {
    if (localStorage.getItem('identity')) {
      const identity = JSON.parse(localStorage.getItem('identity')).user;
      return identity.enabled;
    } 
    return '';
  }

  public updateIdentity(user: Identity) {
    //this._identity.next(user);
  }

  setView(type = 'thumb') {
    this._view.next(type);
  }

}