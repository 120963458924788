import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../services/login.service';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loading = false;
  public background;
  public listado: any[] = [];
  public login = true;
  public register = false;
  public recovery = false;
  public second_form = false;
  token:string = null;

  formLogin:FormGroup;

  constructor(
    private _sanitizer: DomSanitizer, 
    private _router:ActivatedRoute,
    private _routerN:Router,
    private _fb:FormBuilder,
    private _login:LoginService
  ) { 
    
    this.formLogin = this.getForm();
  }

  getForm(){
    return this._fb.group({
      'username':[null, Validators.required],
      'password': [null, Validators.required],
    });
  }

  onSubmit(){
    this.loading = true;
    this._login.signup(this.formLogin.getRawValue()).then(
      (response:any)=>{
        this.loading = false;
        this._routerN.navigate(['/'])
      }
    ).catch(error=>{console.log(error), this.loading = false;});
  }

  ngOnInit() {
    this.onGetBackground();
  }

  onGetBackground(){
    this.listado.push("position: fixed; width: 100vw; height: 100vh; top: 0; left: 0; background: url(assets/images/background.jpg) no-repeat center center fixed;  -webkit-background-size: cover;  -moz-background-size: cover;  -o-background-size: cover;  background-size: cover;")
    this.background = this.getBackground();
  } 
private getBackground = function () {
    var aleatorio = Math.floor(Math.random() * (this.listado.length));
    var bg = this.listado[aleatorio];
    return this._sanitizer.bypassSecurityTrustStyle(bg);
};

onVoted(event) {
  if (event === 'register') { this.second_form = false; this.register = true; this.login = false; this.recovery = false; this.background = this.getBackground(); }
  if (event === 'recovery') { this.second_form = false; this.register = false; this.login = false; this.recovery = true; this.background = this.getBackground(); }
  if (event === 'login') {  this.second_form = false; this.register = false; this.login = true; this.recovery = false; this.background = this.getBackground(); }
}

}
