export class Register{
    name_client: string;
    email_client:string;
    phone_client:string; 
    accept_term: boolean;
    download_image: boolean ;
    text_image: string;
    accept_receiver_information: boolean;

    constructor(datos?){
        let register = datos || {};
        this.name_client                 = register.name_client || null;
        this.email_client                = register.email_client || null;
        this.phone_client                = register.phone_client || null;
        this.accept_term                 = register.accept_term || 0;
        this.download_image              = register.download_image || 0;
        this.text_image                  = register.text_image || null;
        this.accept_receiver_information = register.accept_receiver_information || 0;
    }
}