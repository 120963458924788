import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { Injectable} from '@angular/core';
import { LoginService } from '../services/login.service';
import { JwtHelperService } from '@auth0/angular-jwt';



@Injectable()
export class AdminGuard implements CanActivate {

    constructor(private jwt: LoginService, private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const redirectUrl = route['_routerState']['url'];
          if (this.jwt.loggedIn()) {
            
              //if(!this.jwt.issetAuthoriztion()){
              //  this.router.navigateByUrl(
              //    this.router.createUrlTree(
              //      ['/login']
              //    )
              //  );
              //}
              return true;
          }
          this.router.navigateByUrl(
            this.router.createUrlTree(
              ['/login'], {
                queryParams: {
                  redirectUrl
                }
              }
            )
          );
            
        this.jwt.logout();
        return false;
    }
    
    
    
}
