import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { HeaderService } from './header.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private headerService:HeaderService) { }

  @Input('search') search:ElementRef<HTMLInputElement>;
  ngOnInit() {
    
  }

  onKeyPress(event:any){
    this.headerService.setSeaarch(event.target.value);
    console.log(event);
  }




}
