import { Component, OnInit, ViewChild, ElementRef, HostListener, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { Texts } from '../pages/texts.model';
import { SendImagesService } from '../pages/images/send-images/send-images.service';
import { UtilsService } from '../services/utils.service';
import { takeUntil } from 'rxjs/operators';
import config from '../config/config';
import Swal from 'sweetalert2';

declare var $: any;

@Component({
  selector: 'app-brindis',
  templateUrl: './brindis.component.html',
  styleUrls: ['./brindis.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BrindisComponent implements OnInit, AfterViewInit{
  @ViewChild('myCanvas', {static: true}) 
  myCanvas: ElementRef<HTMLCanvasElement>;
  background:any = null;
  listado:any = [];
  client:any = {
    number:'',
    caption: ''
  };

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    console.log('resize');
    event.target.innerWidth;
  }

  texts:Texts[] = [];
  texts$:BehaviorSubject<any>;
  nameImage:string;
  imageBase64:string;

  form:FormGroup;

  //texts:Texts[] = [];

  public context: CanvasRenderingContext2D;
  
  view = true;

  // Private
  private _unsubscribeAll: Subject<any>;

  public imagePath;
  imgURL: any;
  public message: string;
  imagen = null;
  types: any;
  fonts: any;

  cssUrl: string;

  constructor(private utilsService:UtilsService, 
    private fb: FormBuilder,
    private _sanitizer:DomSanitizer,
    private sendImageService:SendImagesService) { 
    this._unsubscribeAll = new Subject();
    this.cssUrl = '/assets/styles.css';
    this.types= this.utilsService.getTypesText();
    this.fonts = this.utilsService.getTypesFonts();
  }
  ngAfterViewInit() 
  {
       
    
    
    
  }

  ngOnInit() {
    this.texts$ = new BehaviorSubject({});
    this.onGetBackground();
    
   

    this.sendImageService.onImageChange.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(
      (response:any) => {
        this.nameImage = response.name;
        
        this.imagen = new Image();
        this.imagen.crossOrigin = 'anonymous';
        this.imagen.src = config.urlApi+'images_posters/'+response.base64encode;
        this.imagen.onload = () => {
            
            this.cargarImage(this.imagen);
            this.texts = JSON.parse(response.texts);
            this.texts$.next(this.texts);
          
        }
        this.texts = [];
        this.texts$.next(this.texts);
      
      },
      (error)=>console.log(error)
    )
    this.sendImageService.getImageDefault();


    this.texts$.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(
      (response) => {
        this.texts = response;
        console.log(this.texts);
        this.context = this.myCanvas.nativeElement.getContext('2d');
        this.context.fillRect(0,0,this.myCanvas.nativeElement.width,this.myCanvas.nativeElement.height);
        
        this.context.save();
        if(this.imagen){
          this.context.drawImage(this.imagen, 0, 0, this.myCanvas.nativeElement.width, this.myCanvas.nativeElement.height);
          this.context.save();
        }
        this.texts.forEach((element:any)=>{
          
          var x = this.myCanvas.nativeElement.width / 2;
          var y = this.myCanvas.nativeElement.height / 2;
          // Fill with gradient
          let centerY = (this.myCanvas.nativeElement.height)+parseInt(element.y);
          let centerX = (this.myCanvas.nativeElement.width/2)+parseInt(element.x);
         
          let angle = Math.PI * parseFloat(element.sep);
          
          this.context.textAlign = 'center';
          this.context.fillStyle = 'white';
          this.context.lineWidth = 1;    
          if(element.shadow){
            if(element.type =='arc'){
              this.utilsService.drawTextAlongArc(this.context, element.text, centerX-2, centerY, element.ang, angle, element.colorShadow, element.size, element.font);
            }else{
              this.utilsService.drawTextNormal(this.context, element.text, centerX-2, centerY, angle, element.colorShadow, element.size, element.font);    
            }
          }

          if(element.type == 'arc'){
            this.utilsService.drawTextAlongArc(this.context, element.text, centerX, centerY, element.ang, angle, element.color, element.size, element.font);
          }else {
            this.utilsService.drawTextNormal(this.context, element.text, centerX, centerY,  angle, element.color, element.size,  element.font);
          }

          this.context.restore()
        });
      }
    );
    this.loadForm();
  }

  addText(){
    let text:Texts = {type:'arc', text:'', 'font':this.fonts[0].value, color:'red', size:31, x : 0, y:0, shadow:false, colorShadow:null, sep:this.types[0].attr.angle, ang:this.types[0].attr.radius} ;
    this.texts.push(text);
  }

  deleteTexts(index){
    console.log(index);
    this.texts.splice(index,1);
    this.texts$.next(this.texts);
  }

  changeTypeText($event, index){
    this.texts[index] = {type: this.texts[index].type, text: this.texts[index].text, 
      'font':this.texts[index].font, color:this.texts[index].color, size:this.texts[index].size, x : this.texts[index].x, y:this.texts[index].y, 
      shadow:this.texts[index].shadow, colorShadow:this.texts[index].colorShadow, sep:this.getTypeAttr(this.texts[index].type).attr.angle, ang:this.getTypeAttr(this.texts[index].type).attr.radius}
      this.texts$.next(this.texts);
  }

  getTypeAttr(filter){
    
    let r = this.types.filter((x:any)=>x.type == filter);
    console.log(filter);
    console.log(r);
    return r[0];
  }

  update(event:any){
    this.texts$.next(this.texts);
  }

  loadForm(){
    this.form = this.fb.group({
      'number': [this.client.number, Validators.required],
      'caption': [this.client.caption, Validators.required],
    });

  }

  onDescargar(){
        let link = document.createElement('a');
        link.download = this.imagen.name;
        let download = true;
        this.texts.forEach((element:any)=>{
          if(element.text.length == 0){
            download = false;
          }
        });
        if(download){
          link.href = this.myCanvas.nativeElement.toDataURL("image/png",1);
				  link.click();
        }else{
          Swal.fire(
            'Atenção!',
            'Preencha um nome.',
            'info'
          )
          ;
        }
        
  }


  onchage(){
    this.texts$.next(this.texts);
  }

  preview(files) {
   
    
    
      
    
  }

  cargarImage(imagen){
    this.context = this.myCanvas.nativeElement.getContext('2d');
    
    this.context.fillStyle='rgb(255,255,255)';
    this.context.fillRect(0,0,this.myCanvas.nativeElement.width,this.myCanvas.nativeElement.height);
      // vuelve a dibujar todo
    this.context.beginPath();
    imagen.crossOrigin = 'anonymous';
    this.context.drawImage(imagen, 0, 0, 380, 547);
    this.context.save();
  }


  
  
  onGetBackground(){
    this.listado.push("position: absolute; width: 100vw; height: 100vh; top: 0; left: 0; background: url(assets/images/brindis.jpg) no-repeat center center fixed;  -webkit-background-size: cover;  -moz-background-size: cover;  -o-background-size: cover;  background-size: cover;")
    this.background = this.getBackground();
  } 
  private getBackground = function () {
      var aleatorio = Math.floor(Math.random() * (this.listado.length));
      var bg = this.listado[aleatorio];
      return this._sanitizer.bypassSecurityTrustStyle(bg);
  };

  
  
}









