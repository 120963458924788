const config = {
    //urlApi: 'https://express.mariareinaargentina.org/'
    //urlApi: 'http://localhost:3200/',
    //urlPathBrindis: 'http://localhost:4200/brindis/',
    urlApi: 'https://backend.regalodelavirgen.com/',
    urlPathBrindis: 'https://regalodelavirgen.com/brindis/',
    apiWhatsapp: 'https://eu125.chat-api.com/instance131098/',
    token: 'lpubd9ygvd4avupu',
    pathSendEmail:'sendFile'
}
export default config; 