import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import config from '../config/config';
import { IdentityService } from './identity.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private _http: HttpClient, private _identity: IdentityService) { }

  signup(user_to_login) {
    // let json = JSON.stringify(user_to_login);
    const headerOption = {
      headers: new HttpHeaders({'Content-Type': 'application/json'})
    };
   
    return new Promise((resolve, reject) => {
      this._http.post<any>(config.urlApi + 'auth/login', user_to_login, headerOption)
      .subscribe((resp:any) => {
            localStorage.setItem('token',resp);
            //localStorage.setItem('identity', JSON.stringify(resp.user));
            resolve(resp);
          },
          (error) => {console.log(error); reject(error); }
      );
    });
  }
 

  getToken() {
    return localStorage.getItem('token');
  }

  logout() {
    localStorage.clear();
  }

  public loggedIn(): boolean {
    return this.getToken() !==  null;
  }
  public issetAuthoriztion() {
    return localStorage.getItem('authorization');
  }

  public getRestIdentity() {
      return new Promise((resolve, reject) => {
        this._http.get<any>(config.urlApi + 'api/identity').subscribe((resp) => {
          this._identity.updateIdentity(resp.user);
          localStorage.setItem('identity', JSON.stringify(resp));
          localStorage.setItem('location', JSON.stringify(resp.location));
          localStorage.setItem('ip', JSON.stringify(resp.ip));
            resolve(resp);
        }, (error) => {reject(error); });
      });
  }
  public authorization(params) {
      return new Promise((resolve, reject) => {
        this._http.post<any>(config.urlApi + 'auth/check-authorization', params).subscribe(
          (resp) => {
            localStorage.setItem('authorization', 'valid');
            resolve(resp);
          }, (error) => {reject(error); }
        );
      });
  }

  public checkAuthorization(token_ca) {
      const params = {token: token_ca};
      return new Promise((resolve, reject) => {
        this._http.post<any>(config.urlApi + 'api/check/authorization', params).subscribe((data) => {
          resolve(data);
        }, error => { reject(error); });
      });
  }

  public reSendAuthorization() {
    return new Promise((resolve, reject) => {
      this._http.get(config.urlApi + 'api/resend_token_access').subscribe((data) => {
        resolve(data);
      }, error => { reject(error); });
    });
  }
  public checkTokenSecondForm(token:string) {
    return new Promise((resolve, reject) => {
      this._http.get(config.urlApi + 'api/complete-data/'+token).subscribe((data) => {
        resolve(data);
      }, error => { reject(error); });
    });
  }
}