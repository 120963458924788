import { Component, OnInit, ViewChild, ElementRef, HostListener, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { Texts } from '../pages/texts.model';
import { SendImagesService } from '../pages/images/send-images/send-images.service';
import { UtilsService } from '../services/utils.service';
import { takeUntil } from 'rxjs/operators';
import config from '../config/config';
import Swal from 'sweetalert2';
import { Register } from './register.model';
import { Brindis2Service } from './brindis2.service';


declare var $: any;

@Component({
  selector: 'app-brindis2',
  templateUrl: './brindis2.component.html',
  styleUrls: ['./brindis2.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class Brindis2Component implements OnInit, AfterViewInit{
  @ViewChild('myCanvas', {static: true}) 
  myCanvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('myCanvas2', {static: true}) 
  myCanvas2: ElementRef<HTMLCanvasElement>;

  background:any = null;
  listado:any = [];
  client:any = {
    number:'',
    caption: ''
  };
  register:Register;

  form:FormGroup;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
  }

  texts:Texts[] = [];
  texts$:BehaviorSubject<any>;
  nameImage:string;
  imageBase64:string;



  //texts:Texts[] = [];

  public context: CanvasRenderingContext2D;
  
  view = true;

  // Private
  private _unsubscribeAll: Subject<any>;

  public imagePath;
  imgURL: any;
  public message: string;
  imagen = null;
  types: any;
  fonts: any;
  public brindis:any;

  cssUrl: string;

  constructor(private utilsService:UtilsService, 
    private fb: FormBuilder,
    private _sanitizer:DomSanitizer,
    private brindis2servicce:Brindis2Service,
    private sendImageService:SendImagesService) { 
    this._unsubscribeAll = new Subject();
    this.cssUrl = '/assets/styles.css';
    this.types= this.utilsService.getTypesText();
    this.fonts = this.utilsService.getTypesFonts();
  }


  ngAfterViewInit() 
  {
       
    
    
    
  }

  ngOnInit() {
    this.texts$ = new BehaviorSubject({});
    this.onGetBackground();
    this.register = new Register();
    
   this.brindis2servicce.onBrindisChange.pipe(
     takeUntil(this._unsubscribeAll)
   ).subscribe(
    (response:any) => {
      this.nameImage = response.name;
      
      this.imagen = new Image();
      this.imagen.crossOrigin = 'anonymous';
      this.imagen.src = config.urlApi+'images_posters/'+response.base64encode;
      this.brindis = response.brindis;
      this.imagen.onload = () => {
          
          this.cargarImage(this.imagen);
          this.texts = JSON.parse(response.texts);
          this.texts$.next(this.texts);
        
      }
      this.texts = [];
      this.texts$.next(this.texts);
    
    },
    (error)=>console.log(error)
   )

    


    this.texts$.pipe(
      takeUntil(this._unsubscribeAll)
    ).subscribe(
      (response) => {
        this.texts = response;
        this.context = this.myCanvas.nativeElement.getContext('2d');
        this.context.fillRect(0,0,this.myCanvas.nativeElement.width,this.myCanvas.nativeElement.height);
        
        this.context.save();
        if(this.imagen){
          this.context.drawImage(this.imagen, 0, 0, this.myCanvas.nativeElement.width, this.myCanvas.nativeElement.height);
          this.context.save();
        }
        this.texts.forEach((element:any)=>{
          
          var x = this.myCanvas.nativeElement.width / 2;
          var y = this.myCanvas.nativeElement.height / 2;
          // Fill with gradient
          let centerY = (this.myCanvas.nativeElement.height)+parseInt(element.y);
          let centerX = (this.myCanvas.nativeElement.width/2)+parseInt(element.x);
         
          let angle = Math.PI * parseFloat(element.sep);
          
          this.context.textAlign = 'center';
          this.context.fillStyle = 'white';
          this.context.lineWidth = 1;    
          if(element.shadow){
            if(element.type =='arc'){
              this.utilsService.drawTextAlongArc(this.context, element.text, centerX-2, centerY, element.ang, angle, element.colorShadow, element.size, element.font);
            }else{
              this.utilsService.drawTextNormal(this.context, element.text, centerX-2, centerY, angle, element.colorShadow, element.size, element.font);    
            }
          }

          if(element.type == 'arc'){
            this.utilsService.drawTextAlongArc(this.context, element.text, centerX, centerY, element.ang, angle, element.color, element.size, element.font);
          }else {
            this.utilsService.drawTextNormal(this.context, element.text, centerX, centerY,  angle, element.color, element.size,  element.font);
          }

          this.context.restore()
        });
      }
    );
    this.form = this.loadForm();
  }

  addText(){
    let text:Texts = {type:'arc', text:'', 'font':this.fonts[0].value, color:'red', size:31, x : 0, y:0, shadow:false, colorShadow:null, sep:this.types[0].attr.angle, ang:this.types[0].attr.radius} ;
    this.texts.push(text);
  }

  deleteTexts(index){
    console.log(index);
    this.texts.splice(index,1);
    this.texts$.next(this.texts);
  }

  changeTypeText($event, index){
    this.texts[index] = {type: this.texts[index].type, text: this.texts[index].text, 
      'font':this.texts[index].font, color:this.texts[index].color, size:this.texts[index].size, x : this.texts[index].x, y:this.texts[index].y, 
      shadow:this.texts[index].shadow, colorShadow:this.texts[index].colorShadow, sep:this.getTypeAttr(this.texts[index].type).attr.angle, ang:this.getTypeAttr(this.texts[index].type).attr.radius}
      this.texts$.next(this.texts);
  }

  

  getTypeAttr(filter){
    
    let r = this.types.filter((x:any)=>x.type == filter);
    console.log(filter);
    console.log(r);
    return r[0];
  }

  update(event:any){
    this.texts$.next(this.texts);
  }

  loadForm(){
    if(this.brindis.typeForm!=='simple'){
      return this.fb.group({
        'name_client'                   :[this.register.name_client],
        'email_client'                  :[this.register.email_client, Validators.compose([Validators.required, Validators.email])],
        'phone_client'                  :[this.register.phone_client, Validators.required],
        'accept_term'                   :[this.register.accept_term, Validators.requiredTrue],
        'download_image'                :[this.register.download_image],
        'text_image'                    :[this.register.text_image],
        'accept_receiver_information'   :[this.register.accept_receiver_information],
      });
    }else{
      return this.fb.group({
        'number': [this.client.number, Validators.required],
        'caption': [this.client.caption, Validators.required],
      });
    }
   

  }

  saveInformation(){
    let nameClient = this.form.get('name_client');
    let text2:Texts[] = [];
    let texts2:Texts;
    this.texts.forEach(element =>{
      nameClient.setValue(element.text);
      texts2 = {type:element.type, text:'', 'font':element.font, color:element.color, size:element.size, x : element.x, y:element.y, shadow:element.shadow, colorShadow:element.colorShadow, sep:element.sep, ang:element.ang} ;
    });
    
    this.form.get('download_image').setValue(true);
    this.brindis2servicce.onSaveRegister(this.form.getRawValue()).then(
      (response)=>{
        this.onDescargar();
        this.form.reset();
        text2.push(texts2);
        this.texts$.next(text2)
      }
    ).catch((error:any)=>{
      console.log(error);
      Swal.fire(
        'Em formação!',
        `${error.error}`,
        'error'
      );
    })
    
  }

  onDescargar(){
        let link = document.createElement('a');
        link.download = this.imagen.name;
        let download = true;
        this.texts.forEach((element:any)=>{
          if(element.text.length == 0){
            download = false;
          }
        });
        if(download){
          if(this.brindis.highQuality){
            let myCanvas2 = this.myCanvas2.nativeElement;
            //nuevo canvas
            let imagen = new Image();
            imagen.src = this.myCanvas.nativeElement.toDataURL('image/png', 1);
            imagen.crossOrigin = 'anonymous';
            imagen.onload = () => {
              let context = this.myCanvas2.nativeElement.getContext('2d');
              context.fillStyle='rgb(255,255,255)';
              context.fillRect(0,0,this.myCanvas.nativeElement.width,this.myCanvas.nativeElement.height);
                // vuelve a dibujar todo
              context.beginPath();
              context.drawImage(imagen, 0, 0, 580, 747);
              context.save();            
              link.href = myCanvas2.toDataURL("image/png",1);
              link.click();
            }
          }else{
            link.href = this.myCanvas.nativeElement.toDataURL("image/png",1);
            link.click();
          }
         
          
        }else{
          Swal.fire(
            'Atenção!',
            'Preencha um nome.',
            'info'
          )
          ;
        }
        
  }


  onchage(){
    this.texts$.next(this.texts);
  }

  preview(files) {
   
    
    
      
    
  }

  cargarImage(imagen){
    this.context = this.myCanvas.nativeElement.getContext('2d');
    
    this.context.fillStyle='rgb(255,255,255)';
    this.context.fillRect(0,0,this.myCanvas.nativeElement.width,this.myCanvas.nativeElement.height);
      // vuelve a dibujar todo
    this.context.beginPath();
    imagen.crossOrigin = 'anonymous';
    this.context.drawImage(imagen, 0, 0, 380, 547);
    this.context.save();
  }


  
  
  onGetBackground(){
    this.listado.push("position: absolute; width: 100vw; height: 100vh; top: 0; left: 0; background: #f0ede6 no-repeat center center fixed;  -webkit-background-size: cover;  -moz-background-size: cover;  -o-background-size: cover;  background-size: cover;")
    this.background = this.getBackground();
  } 
  private getBackground = function () {
      var aleatorio = Math.floor(Math.random() * (this.listado.length));
      var bg = this.listado[aleatorio];
      return this._sanitizer.bypassSecurityTrustStyle(bg);
  };

  resample_single(canvas, width, height, resize_canvas) {
      let canvasCopy = document.createElement("canvas");
      let copyContext = canvasCopy.getContext("2d");
      var width_source = canvas.width;
      var height_source = canvas.height;
      width = Math.round(width);
      height = Math.round(height);

      var ratio_w = width_source / width;
      var ratio_h = height_source / height;
      var ratio_w_half = Math.ceil(ratio_w / 2);
      var ratio_h_half = Math.ceil(ratio_h / 2);

      var ctx = canvas.getContext("2d");
      var img = ctx.getImageData(0, 0, width_source, height_source);
      var img2 = ctx.createImageData(width, height);
      var data = img.data;
      var data2 = img2.data;

      for (var j = 0; j < height; j++) {
          for (var i = 0; i < width; i++) {
              var x2 = (i + j * width) * 4;
              var weight = 0;
              var weights = 0;
              var weights_alpha = 0;
              var gx_r = 0;
              var gx_g = 0;
              var gx_b = 0;
              var gx_a = 0;
              var center_y = (j + 0.5) * ratio_h;
              var yy_start = Math.floor(j * ratio_h);
              var yy_stop = Math.ceil((j + 1) * ratio_h);
              for (var yy = yy_start; yy < yy_stop; yy++) {
                  var dy = Math.abs(center_y - (yy + 0.5)) / ratio_h_half;
                  var center_x = (i + 0.5) * ratio_w;
                  var w0 = dy * dy; //pre-calc part of w
                  var xx_start = Math.floor(i * ratio_w);
                  var xx_stop = Math.ceil((i + 1) * ratio_w);
                  for (var xx = xx_start; xx < xx_stop; xx++) {
                      var dx = Math.abs(center_x - (xx + 0.5)) / ratio_w_half;
                      var w = Math.sqrt(w0 + dx * dx);
                      if (w >= 1) {
                          //pixel too far
                          continue;
                      }
                      //hermite filter
                      weight = 2 * w * w * w - 3 * w * w + 1;
                      var pos_x = 4 * (xx + yy * width_source);
                      //alpha
                      gx_a += weight * data[pos_x + 3];
                      weights_alpha += weight;
                      //colors
                      if (data[pos_x + 3] < 255)
                          weight = weight * data[pos_x + 3] / 250;
                      gx_r += weight * data[pos_x];
                      gx_g += weight * data[pos_x + 1];
                      gx_b += weight * data[pos_x + 2];
                      weights += weight;
                  }
              }
              data2[x2] = gx_r / weights;
              data2[x2 + 1] = gx_g / weights;
              data2[x2 + 2] = gx_b / weights;
              data2[x2 + 3] = gx_a / weights_alpha;
          }
      }
      //clear and resize canvas
      if (resize_canvas === true) {
          canvas.width = width;
          canvas.height = height;
      } else {
          ctx.clearRect(0, 0, width_source, height_source);
      }

      //draw
      ctx.putImageData(img2, 0, 0);
  }
  
}










