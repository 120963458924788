import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor(
    private _login:LoginService,
  ) { }

  ngOnInit() {
  }

  onLogout(){
    this._login.logout();
    document.location.href = '/';
  }

}
