import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  getTypesText(){
    return [
      {
        type: 'arc',
        attr: {
          radius: 380,
          angle: 0.2,
        }
      },
      {
        type: 'normal',
        attr: {
          angle: 0,
        }
      },
      
    ];
  }

  getTypesFonts(){
    return [
      {
        type: 'Sandblack',
        value:'sandblacks'
      },
      {
        type: 'Arial',
        value:'arial'
      },
      {
        type:'COPRGTB',
        value: 'COPRGTB'
      },
      {
        type:'COPRGTL',
        value: 'COPRGTL'
      },
      {
        type:'Forte',
        value: 'forte'
      },
      {
        type:'Mistral',
        value: 'mistral'
      },
      {
        type:'Pristina',
        value: 'pristina'
      },
      {
        type:'TrabanPro Regular',
        value: 'trajanproregular'
      },
      {
        type:'Vivaldi',
        value: 'vivaldi'
      },
      {
        type:'Vladimir',
        value: 'vladimir'
      },
    ]
  }

  drawTextAlongArc(context, str, centerX, centerY, radius, angle, color="red", fntSize=31, font='sandblacks') {
    let len = str.length, s;
    context.save();
    context.translate(centerX, centerY);
    context.rotate(-1 * angle / 2);
    context.rotate(-1 * (angle / len) / 2);
    context.font = fntSize+'px '+font;
    for(var n = 0; n < len; n++) {
      context.rotate(angle / len);
      context.save();
      context.translate(0, -1 * radius);
      s = str[n];
      context.fillStyle = color; 
      context.fillText(s, 0, 0);
      context.restore();
    }
    context.restore();
  }

  drawTextNormal(context, str, centerX, centerY, angle, color="red", fntSize=31, font='sandblacks') {
    let len = str.length, s;
    context.save();
    context.translate(centerX, centerY/2);
    context.rotate(angle,0)
    context.font = fntSize+'px '+font;
    context.fillStyle = color; 
    context.fillText(str, 0, 0);
    context.restore();
    context.save();
    context.restore();
  }

  b64toBlob(b64Data, contentType, sliceSize = 512) {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          var slice = byteCharacters.slice(offset, offset + sliceSize);

          var byteNumbers = new Array(slice.length);
          for (var i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
          }

          var byteArray = new Uint8Array(byteNumbers);

          byteArrays.push(byteArray);
      }

    var blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  public getRoles(){
    return [
      {
        name: 'Administrador',
        value: 'ROLE_ADMIN'
      },
      // {
      //   name: 'Usuario',
      //   value: 'ROLE_USER'
      // }
    ]
  }


  

}
