import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpEventType,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    HttpHeaderResponse,
    HttpHeaders
} from '@angular/common/http';

import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { LoginService } from '../services/login.service';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  private refreshTokenInProgress = false;
  // Refresh Token Subject tracks the current token, or is null if no token is currently
  // available (e.g. refresh pending).
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
      null
  );
  constructor(private login: LoginService, private router: Router, private route:ActivatedRoute) {

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.headers.get("skip")){
      request = request.clone({ headers: request.headers.delete('skip') });
           return next.handle(request);
    }else{
      const token: any = localStorage.getItem('token');
    if (token) {
      request = request.clone({ headers: request.headers.set('auth', token) });
    }
    return next.handle(request).pipe(
        tap((event: HttpEvent<any>) => {
          if(event instanceof HttpResponse){
              //console.log(event)
          }
        }, (event) => {
        if (event instanceof HttpErrorResponse) {
              if (event.status === 401 ) {
                
                  this.login.logout();
                  const redirectUrl = this.route['_routerState']['url'];
                  this.router.navigateByUrl(
                    this.router.createUrlTree(
                      ['/login'], {
                        queryParams: {
                          redirectUrl
                        }
                      }
                    )
                  );
                  // Genrate params for token refreshing
                //this.login.refreshToken().then(
                //  (data: any) => {
                //      console.log('ingresamos pare checkar refresh');
                //      const tokenn = JSON.parse(this.login.getToken());
                //      console.log('token recibido:', tokenn);
                //      if (tokenn) {
                //        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + tokenn.access_token) });
                //      }
                //      if (data.status === 200) {
                //        return next.handle(request).pipe(
                //          tap((eventn: HttpEvent<any>) => {
                //            if (eventn instanceof HttpResponse) {
                //                console.log('event2 --->>>', eventn);
                //            }
                //            return eventn;
                //          }, eventn => {
                //            if (eventn instanceof HttpErrorResponse) {
                //              console.log('event2 --->>>', eventn);
                //              console.log('error de refresh 2 logout');
                //              // this.login.logout();
                //            }
                //          })
                //        );
                //      }
                //  }
                //).catch(error => {
                //  console.log('error refresh:', error);
                //  // this.login.logout();
                //});
              } else {
                console.log('error de expired');
                // this.login.logout();
              }
          
        }
        


      }
    )
    );
    }
    

  }

  addAuthenticationToken(request) {
    const token: any = JSON.parse(localStorage.getItem('token'));

    if (!token) {
      return request;
    }

    return request.clone({ headers: request.headers.set('auth', token)});

}
}


