import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import config from '../../../config/config';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SendImagesService {

  image: any;
  onImageChange:BehaviorSubject<any>;
  routeParams:any;
  constructor(private http:HttpClient) { 
    this.onImageChange = new BehaviorSubject({});
  }

  /**
     * Resolver
     *
     * @param {ActivatedRouteSnapshot} route
     * @param {RouterStateSnapshot} state
     * @returns {Observable<any> | Promise<any> | any}
     */
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {
            Promise.all([
                this.getImage()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    /**
     * Get product
     *
     * @returns {Promise<any>}
     */
    getImage(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            if ( this.routeParams.id === 'new' )
            {
                this.onImageChange.next(false);
                resolve(false);
            }
            else
            {
                this.http.get(config.urlApi+'/images/' + this.routeParams.id)
                    .subscribe((response: any) => {
                        this.image = response;
                        this.onImageChange.next(this.image);
                        resolve(this.image);
                    }, reject);
            }
        });
    }

    getImageDefault(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.http.get(config.urlApi+'/images/defaults')
                .subscribe((response: any) => {
                    this.image = response;
                    this.onImageChange.next(this.image);
                    resolve(this.image);
                }, reject);
        });
    }

    getImageDefault2(): Promise<any>
    {
        return new Promise((resolve, reject) => {
            this.http.get(config.urlApi+'/images/defaults2')
                .subscribe((response: any) => {
                    this.image = response;
                    this.onImageChange.next(this.image);
                    resolve(this.image);
                }, reject);
        });
    }


    sendImage(number, filename, caption, blob:Blob){
      let formData = new FormData();
    
        formData.append('body', blob, filename);
        formData.append('phone', number);
        formData.append('filename', filename);
        formData.append('caption', caption);
        

        return new Promise((resolve, reject)=>{
          this.http.post(config.apiWhatsapp+config.pathSendEmail+'?token='+config.token, formData, {headers:{skip:"true"}}).subscribe(
            (response)=>{
              resolve(response)
            },
            (e:any)=>{
              reject(e);
            }
          );
        });
    }

 
}
